import React from "react";

class ScrollToTop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_visible: false
    };
  }

  componentDidMount() {
    var scrollComponent = this;
    document.addEventListener("scroll", function(e) {
      scrollComponent.toggleVisibility();
    });
  }

  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  render() {
    const { is_visible } = this.state;
    return (
      <div className="scroll-to-top">
        {is_visible && (
          <div onClick={() => this.scrollToTop()}>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M48 24C48 37.255 37.255 48 24 48C10.745 48 0 37.255 0 24C0 10.745 10.745 0 24 0C37.255 0 48 10.745 48 24Z" fill="#00f2c3"/>
            <path d="M0 24C0 37.233 10.767 48 24 48C37.233 48 48 37.233 48 24H0Z" fill="#00c2a3"/>
            <path d="M31.4231 17.3024L23.9996 9.87891L16.5762 17.3024L18.6973 19.4235L22.4996 15.6211V26.9777H25.4996V15.6211L29.302 19.4235L31.4231 17.3024Z" fill="white"/>
            <path d="M22.5 29.9775H25.5V32.9775H22.5V29.9775Z" fill="white"/>
            <path d="M22.5 35.9775H25.5V38.9775H22.5V35.9775Z" fill="white"/>
            </svg>
          </div>
        )}
      </div>
    );
  }
}


export default ScrollToTop;
