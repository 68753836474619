import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import "./assets/css/nucleo-icons.css";
import "./assets/scss/neev-design-system-react.scss?v=1.0.0";
import "./assets/demo/demo.css";

import LandingPage from "./views/examples/LandingPage.jsx";
import AboutPage from "./views/examples/AboutPage.jsx";
import ForeignLanguagePage from "./views/examples/ForeignLanguagePage.jsx";
import RegisterPage from "./views/examples/RegisterPage.jsx";
import LogInPage from "./views/examples/LogInPage.jsx";

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/home" element={<LandingPage />}></Route>
      <Route path="/about" element={<AboutPage />}></Route>
      <Route
        path="/learn-foreign-lang"
        element={<ForeignLanguagePage />}
      ></Route>
      <Route path="/signup" element={<RegisterPage />}></Route>
      <Route path="/login" element={<LogInPage />}></Route>
      <Route path="/" element={<Navigate to="/home" />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);
