import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import classnames from "classnames";

// Images
import Blob from "../../assets/img/blob.png";
import path2 from "../../assets/img/path2.png";
import triunghiuri from "../../assets/img/triunghiuri.png";
import waves from "../../assets/img/waves.png";
import patrat from "../../assets/img/patrat.png";
import cercuri from "../../assets/img/cercuri.png";
import home_banner_2 from "../../assets/img/home-banner-2.svg";
// reactstrap components
import {
  Button,
  CardFooter,
  Form,
  Input,
  // InputGroupAddon,
  InputGroupText,
  InputGroup,
  Card,
  Row,
  Col,
  UncontrolledAlert,
  Modal,
} from "reactstrap";

const nameRegex = RegExp(/^[a-zA-Z ]*$/);

class PageHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      phone: "",
      showModal: false,
      popupStatus: "failure",
      popupInfo: "",
    };

    this.formErrors = {
      name: false,
      phone: false,
      nameError: "",
      phoneError: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handelChange = this.handelChange.bind(this);
    this.showPopup = this.showPopup.bind(this);
  }

  async showPopup() {
    this.setState({
      showModal: true,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (this.state.name.length > 0 && this.state.phone.length > 0) {
      if (this.formErrors.name === false && this.formErrors.phone === false) {
        const details = {
          name: this.state.name.toLowerCase().toString(),
          phone: this.state.phone.toString(),
        };
        await fetch(`/addnew?name=${details.name}&phone=${details.phone}`)
          .then((res) => res.json())
          .then(
            (result) => {
              this.setState({
                isLoaded: true,
                popupStatus: result.task,
                popupInfo: result.info,
              });
            },
            (error) => {
              this.setState({
                isLoaded: false,
                popupInfo: "Some error happened...try again later",
                error,
              });
              console.log(error);
            }
          )
          .then(() => {
            this.showPopup();
          });
      }
    } else {
      console.log("Invalid Credentials");
    }
  }

  handelChange(event) {
    event.preventDefault();
    const { name, value } = event.target;
    switch (name) {
      case "name":
        this.formErrors.name =
          nameRegex.test(value) && value.length > 2 ? false : true;
        this.formErrors.nameError =
          nameRegex.test(value) && value.length > 6 ? "" : "invalid name";
        this.setState({
          name: value,
        });
        break;
      case "phone":
        this.formErrors.phone = value.length !== 10 ? true : false;
        this.formErrors.phoneError =
          value.length !== 10 ? "phone number must be 10 digits" : "";
        this.setState({
          phone: value,
        });
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <div className="page-header">
        <Modal
          modalClassName={
            this.state.popupStatus === "success"
              ? "modal-mini modal-success"
              : this.state.popupStatus === "failure"
              ? "modal-mini modal-danger"
              : "modal-mini modal-danger"
          }
          isOpen={this.state.showModal}
        >
          <div
            className={
              this.state.popupStatus === "success"
                ? "modal-body text-dark"
                : "modal-body"
            }
          >
            {this.state.popupInfo}
          </div>
          <div className="modal-footer">
            <Button
              className="btn-neutral"
              color="link"
              onClick={() =>
                this.setState({
                  showModal: false,
                })
              }
              type="button"
            ></Button>
            <Button
              className="btn-neutral"
              color="link"
              onClick={() =>
                this.setState({
                  showModal: false,
                })
              }
              type="button"
            >
              Close
            </Button>
          </div>
        </Modal>
        <div>
          <img src={Blob} alt="..." className="path" />
        </div>
        <img alt="..." className="path2" src={path2} />
        <img alt="..." className="shapes triangle" src={triunghiuri.png} />
        <img alt="..." className="shapes wave" src={waves} />
        <img alt="..." className="shapes squares" src={patrat} />
        <img alt="..." className="shapes circle" src={cercuri} />
        <Container>
          <div className="content-center">
            <Row className="row-grid justify-content-between align-items-center text-left">
              <Col lg="7" md="8">
                <img alt="..." className="img-fluid" src={home_banner_2} />
              </Col>

              <Col lg="5" md="4" className="text">
                <Card className="p-4 getStarted">
                  <h1>
                    Get Personalised Learning, homework specially crafted for
                    your child
                    <br />
                    {/* <span className="">secured</span> */}
                  </h1>
                  <p className="mb-3">
                    Every Child is special and unique in his/her own way.
                    <br />
                    They need to be nourished according to their interests and
                    abilities.
                  </p>
                  <h4>Get a call from our experts</h4>
                  <Form onSubmit={this.handleSubmit} className="form">
                    <div className="m-0 d-flex flex-col">
                      <div className="px-0">
                        <InputGroup>
                          {/* <Button addonType="prepend"> */}
                          <InputGroupText>
                            <i className="tim-icons icon-single-02" />
                          </InputGroupText>
                          {/* </Button> */}
                          <Input
                            placeholder="Name"
                            className="no-right-border"
                            name="name"
                            type="text"
                            onFocus={(e) => this.setState({ emailFocus: true })}
                            onBlur={(e) => this.setState({ emailFocus: false })}
                            onChange={this.handelChange}
                          />
                        </InputGroup>
                      </div>
                      <div className="px-0">
                        <InputGroup>
                          {/* <Button addonType="prepend"> */}
                          <InputGroupText>
                            <i className="tim-icons icon-mobile" />
                          </InputGroupText>
                          {/* </Button> */}
                          <Input
                            placeholder="Phone No."
                            name="phone"
                            type="number"
                            onFocus={(e) => this.setState({ emailFocus: true })}
                            onBlur={(e) => this.setState({ emailFocus: false })}
                            onChange={this.handelChange}
                          />
                        </InputGroup>
                      </div>
                    </div>
                    <UncontrolledAlert
                      className={classnames({
                        "d-none alert-with-icon": !this.formErrors.name,
                        "alert-with-icon": this.formErrors.name,
                      })}
                      color="danger"
                    >
                      <span>{this.formErrors.nameError}</span>
                    </UncontrolledAlert>
                    <UncontrolledAlert
                      className={classnames({
                        "d-none alert-with-icon": !this.formErrors.phone,
                        "alert-with-icon": this.formErrors.phone,
                      })}
                      color="danger"
                    >
                      <span>{this.formErrors.phoneError}</span>
                    </UncontrolledAlert>
                    <CardFooter className="px-0 mt-md-0">
                      <Button
                        type="submit"
                        value="submit"
                        color="success"
                        size="lg"
                      >
                        Get Started
                      </Button>
                    </CardFooter>
                  </Form>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}

export default PageHeader;
