import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

class ForeignLanguagesBody extends React.Component {
  render() {
    return (
      <div className="section section-javascript" id="javascriptComponents">
        <img
          alt="..."
          className="path path1"
          src={require("assets/img/path5.png")}
        />
        <div className="section">
          <Container>
            <Row className="py-50 justify-content-between align-items-center">
              <Col lg="12">
                <h1 className="text-center">
                  Learn Foreign language
                  <hr className="line-success" />
                </h1>
              </Col>
              <Col lg="5" className="d-block d-sm-none">
              <img
                alt="hello"
                src={require("assets/img/hello.png")}
              />
              </Col>
              <Col lg="7">
                <p className="mt-4 text-justify">
                  <strong>Talk to the World!</strong><br/>
                  Do you dream of conversing in different languages, but don't know where to start?<br />
                  Does speaking different languages feel like a cumbersome task to you?<br /><br />
                </p>
                <p className="text-justify">
                  Well, NOT ANYMORE.<br /><br />
                  NEEV brings for you a new FOREIGN LANGUAGES wing where we shall help you make your dreams come true.<br />
                  From French to Chinese, from German to Korean, and many more in list, our language experts shall be teaching all your favorite languages under one roof.<br />
                  Be it your Passion, your Fascination or your Career prospect, NEEV will help you sail through your goals.<br />
                  The new FOREIGN LANGUAGES wing will be a one-stop solution for all your language needs. Here, we'll also guide you how learning new languages can help you shape your career through scholarships, workshops, certification courses, etc. <br />
                </p>
                <br />
                <a href="https://docs.google.com/forms/d/1F6DJ_rncSA_g9nhyJ2hzDJWFT1xlB0osA9IPEr0STyI/edit#question=150928678&field=789845240">
                  <Button
                    className="nav-link d-none d-block btn-lg text-white"
                    color="success"
                  >
                    <strong>Contact Now!</strong>
                  </Button>
                </a>
                <br />
                <p className="text-justify">
                  Let NEEV contribute towards achieving your goals.
                </p>
              </Col>
              <Col lg="5" className="d-none d-sm-block">
              <img
                alt="hello"
                src={require("assets/img/hello.png")}
              />
              </Col>
              <Col lg="12">
                <p className="text-right">
                  Follow us on: 
                  <Button
                    className="mx-3 btn-icon btn-round"
                    color="default"
                    href="https://www.instagram.com/invites/contact/?i=13wixcgzyjh6x&utm_content=lsp6b8o"
                    target="_blank"
                  >
                  <i className="fab fa-instagram" />
                </Button>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section benefits">
          <Container>
            <Row className="py-50 justify-content-between align-items-center">
              <Col md="3" className="lang-desc">
                <img
                  alt="ch-flag"
                  src={require("assets/img/ch-flag.jpeg")}
                />
                <h4 className="text-center mt-4">CHINESE</h4>
                <p className="text-center">
                  CHINESE is one of the most spoken languages with 2 billion speakers in the world. Chinese is best for Business.
                </p>
              </Col>
              <Col md="3" className="lang-desc">
                <img
                  alt="ch-flag"
                  src={require("assets/img/fr-flag.jpeg")}
                />
                <h4 className="text-center mt-4">FRENCH</h4>
                <p className="text-center">
                  If the French language were a credit card, it’d be MasterCard: inherently valuable and almost universally accepted.
                </p>
              </Col>
              <Col md="3" className="lang-desc">
                <img
                  alt="ch-flag"
                  src={require("assets/img/gm-flag.jpeg")}
                />
                <h4 className="text-center mt-4">GERMAN</h4>
                <p className="text-center">
                Learn German and enjoy travelling with your Schengen visa. It is the most famous and recognized language in Europe.
                </p>
              </Col>
              <Col md="3" className="lang-desc">
                <img
                  alt="ch-flag"
                  src={require("assets/img/ja-flag.jpeg")}
                />
                <h4 className="text-center mt-4">JAPANESE</h4>
                <p className="text-center">
                The Japanese make up the third largest language community on the Internet, after only English and Chinese speakers. An estimated 88 million Japanese, or 9.6% of the world.
                </p>
              </Col>
              <Col md="3" className="lang-desc">
                <img
                  alt="ch-flag"
                  src={require("assets/img/ks-flag.jpeg")}
                />
                <h4 className="text-center mt-4">KOREAN</h4>
                <p className="text-center">
                Koreans have 60 million speakers in the world, rise in demand of Korean Lingo ranks it among top 10 foreign languages spoken in USA.
                </p>
              </Col>
              <Col md="3" className="lang-desc">
                <img
                  alt="ch-flag"
                  src={require("assets/img/rs-flag.jpeg")}
                />
                <h4 className="text-center mt-4">RUSSIAN</h4>
                <p className="text-center">
                Russian is 2nd most widespread one of the six official language of UNO, having 144 million speakers across the world.
                </p>
              </Col>
              <Col md="3" className="lang-desc">
                <img
                  alt="ch-flag"
                  src={require("assets/img/sp-flag.jpeg")}
                />
                <h4 className="text-center mt-4">SPANISH</h4>
                <p className="text-center">
                Spanish provides opportunities for Study, Immigration and Business in 22 countries of the world especially America, having 572 million speakers in more than 22 countries, Spanish stands 2nd amongst Languages.
                </p>
              </Col>

            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default ForeignLanguagesBody;
