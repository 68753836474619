import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import logo from "../../assets/img/icon.svg";
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

class ComponentsNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.changeColor);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.changeColor);
  }
  changeColor = () => {
    if (
      document.documentElement.scrollTop > 49 ||
      document.body.scrollTop > 49
    ) {
      this.setState({
        color: "bg-info",
      });
    } else if (
      document.documentElement.scrollTop < 50 ||
      document.body.scrollTop < 50
    ) {
      this.setState({
        color: "navbar-transparent",
      });
    }
  };
  toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  onCollapseExiting = () => {
    this.setState({
      collapseOut: "collapsing-out",
    });
  };
  onCollapseExited = () => {
    this.setState({
      collapseOut: "",
    });
  };
  scrollToDownload = () => {
    document
      .getElementById("download-section")
      .scrollIntoView({ behavior: "smooth" });
  };
  render() {
    return (
      <>
        {/* <Navbar
        className={"fixed-top bg-primary pre-nav py-0"}
        expand="lg"
        >
          <Container>
            <Row className="text-center">
              <Col lg="8" md="8" xs="4">
                <Button
                className="text-white"
                color="link"
                tag={Link}
                to="\"
                >
                  <i className="tim-icons icon-square-pin d-md-none"></i><span className="text"><i className="tim-icons icon-square-pin"></i> Siddharth Colony, Mustafabad, Near Devi Sthan, P.O. and P.S. – Rampur, Gaya, Bihar- 823001</span> 
                </Button>
              </Col>
              <Col lg="2" md="2" xs="4">
                <Button
                className="text-white"
                color="link"
                href="mailto:neev.help@gmail.com"
                >
                  <i className="tim-icons icon-email-85 d-md-none"></i><span className="text"><i className="tim-icons icon-email-85"></i> neev.help@gmail.com</span> 
                </Button>
              </Col>
              <Col lg="2" md="2" xs="4">
                <Button
                className="text-white"
                color="link"
                href="tel:+917700842736"
                >
                  <i className="tim-icons icon-tablet-2 d-md-none"></i><span className="text"><i className="tim-icons icon-tablet-2"></i> +917700842736</span> 
                </Button>
              </Col>
            </Row>  
          </Container> 
        </Navbar> */}
        <Navbar
          className={"fixed-top " + this.state.color}
          color-on-scroll="100"
          expand="lg"
        >
          <Container className="d-flex justify-content-between">
            {/* <div className="navbar-translate"> */}
            <NavbarBrand
              data-placement="bottom"
              to="/"
              rel="noopener noreferrer"
              title="Neev - Laying Foundation"
              style={{ margin: 0 }}
              tag={Link}
            >
              <span className="d-inline-flex flex-column">
                <img alt="..." className="img-fluid" src={logo} />
                <span className="h6 m-auto  d-sm-inline d-md-none" style={{ fontSize: '0.5rem' }}>Laying</span>
                <span className="h6 m-auto d-sm-inline d-md-none" style={{ fontSize: '0.5rem' }}>
                  Foundation
                </span>
              </span>
              <span
                className="h3 pl-3 d-none d-md-inline-block"
                style={{ verticalAlign: "middle" }}
              >
                Laying Foundation
              </span>
            </NavbarBrand>
            <button
              aria-expanded={this.state.collapseOpen}
              className="navbar-toggler navbar-toggler"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
            {/* </div> */}
            <Collapse
              className={"justify-content-end " + this.state.collapseOut}
              navbar
              isOpen={this.state.collapseOpen}
              onExiting={this.onCollapseExiting}
              onExited={this.onCollapseExited}
            >
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Button
                      tag={Link}
                      to="/"
                      color="link"
                      onClick={(e) => e.preventDefault()}
                    >
                      NEEV - Laying Foundation
                    </Button>
                  </Col>
                  <Col className="collapse-close text-right" xs="6">
                    <button
                      aria-expanded={this.state.collapseOpen}
                      className="navbar-toggler"
                      onClick={this.toggleCollapse}
                    >
                      <i className="tim-icons icon-simple-remove" />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav navbar>
                <NavItem className="p-0">
                  <NavLink
                    data-placement="bottom"
                    tag={Link}
                    to="/home"
                    rel="noopener noreferrer"
                    title="Home"
                    className="active"
                  >
                    &nbsp;&nbsp; Home &nbsp;&nbsp;
                  </NavLink>
                </NavItem>
                <NavItem className="p-0">
                  <NavLink
                    data-placement="bottom"
                    tag={Link}
                    to="/about"
                    rel="noopener noreferrer"
                    title="About Us"
                  >
                    &nbsp;&nbsp; About Us &nbsp;&nbsp;
                  </NavLink>
                </NavItem>
                <NavItem className="p-0">
                  <Button
                    className="nav-link d-none d-block btn-lg text-white"
                    color="outline-success"
                    tag={Link}
                    to="/learn-foreign-lang"
                    rel="noopener noreferrer"
                    title="Learn Foreign Languages"
                  >
                    &nbsp;&nbsp; Foreign Languages &nbsp;&nbsp;
                  </Button>
                </NavItem>
                <NavItem className="nav-btn">
                  <Button
                    className="nav-link d-none d-block btn-link btn-lg"
                    tag={Link}
                    to="/login"
                  >
                    <i className="tim-icons icon-lock-circle" /> Log In
                  </Button>
                </NavItem>
                <NavItem className="nav-btn">
                  <Button
                    className="nav-link d-none d-block btn-lg text-white"
                    color="success"
                    tag={Link}
                    to="/signup"
                  >
                    &nbsp;&nbsp; <i className="tim-icons icon-single-02" />{" "}
                    Register For Free&nbsp;&nbsp;
                  </Button>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default ComponentsNavbar;
