import React from "react";
import { Link } from "react-router-dom";

import icon from "assets/img/icon128X128.svg";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <Container>
          <Row>
            <Col md="3">
              <span>
                <img alt="..." className="img-fluid" src={icon} />
              </span>
            </Col>
            <Col md="2">
              <Nav>
                <NavItem>
                  <NavLink to="/" tag={Link}>
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/about" tag={Link}>
                    About US
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/login" tag={Link}>
                    login
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/signup" tag={Link}>
                    register
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="5">
              <Nav>
                <NavItem>
                  <NavLink href="#">
                    <i className="tim-icons icon-square-pin d-md-none" />
                    <span className="text">
                      {" "}
                      Siddharth Colony, Mustafabad, Near Devi Sthan, P.O. and
                      P.S. – Rampur, Gaya, Bihar- 823001
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#">
                    <i className="tim-icons icon-email-85 d-md-none" />
                    <span className="text"> neev.help@gmail.com</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#">
                    <i className="tim-icons icon-tablet-2 d-md-none" />
                    <span className="text"> +917700842736</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="2">
              <h3 className="title">Follow us:</h3>
              <div className="btn-wrapper profile">
                <Button
                  className="btn-icon btn-neutral btn-round btn-simple"
                  color="default"
                  href="#"
                  id="tooltip622135962"
                  target="_blank"
                >
                  <i className="fab fa-twitter" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip622135962">
                  Follow us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon btn-neutral btn-round btn-simple"
                  color="default"
                  href="#"
                  id="tooltip230450801"
                  target="_blank"
                >
                  <i className="fab fa-facebook-square" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip230450801">
                  Like us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon btn-neutral btn-round btn-simple"
                  color="default"
                  href="#"
                  id="tooltip318450378"
                  target="_blank"
                >
                  <i className="fab fa-linkedin" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip318450378">
                  Follow us
                </UncontrolledTooltip>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
