import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  // CardHeader,
  CardBody,
  CardFooter,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

class ChooseAPlan extends React.Component {
  render() {
    return (
      <section className="section section-lg section-coins chooseAPlan">
        <img alt="..." className="path" src={require("assets/img/path3.png")} />
        <Container>
          <Row className="headers">
            <h1 className="text-center">
              Our Courses
              <hr className="line-success" />
            </h1>
          </Row>
          <Row className="headers">
            <h4 className="text-center">
              We excel our students in Science, Maths and also focus on
              increasing their Mental Ability.
            </h4>
          </Row>
          <Row>
            <Col lg="3" sm="6">
              <Card className="card-coin card-plain">
                <CardBody>
                  <Row>
                    <Col className="text-center" md="12">
                      <h4 className="text-uppercase">Pre-Starter</h4>
                      <p>At this stage we marinate you.</p>
                      <hr className="line-primary" />
                    </Col>
                  </Row>
                  <Row>
                    <ListGroup>
                      <ListGroupItem>Class VIIth</ListGroupItem>
                      {/* <ListGroupItem>MON- SAT</ListGroupItem>
                    <ListGroupItem>Class time - 5:00 pm – 6:00 pm</ListGroupItem> */}
                    </ListGroup>
                  </Row>
                </CardBody>
                <CardFooter className="text-center">
                  <Link to="/signup">
                    <Button className="btn" outline color="success">
                      Enroll
                    </Button>
                  </Link>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" sm="6">
              <Card className="card-coin card-plain">
                <CardBody>
                  <Row>
                    <Col className="text-center" md="12">
                      <h4 className="text-uppercase">Starter</h4>
                      <p>Here we start heating you up.</p>
                      <hr className="line-warning" />
                    </Col>
                  </Row>
                  <Row>
                    <ListGroup>
                      <ListGroupItem>Class VIIIth</ListGroupItem>
                      {/* <ListGroupItem>MON- SAT</ListGroupItem>
                    <ListGroupItem>Class time - 5:00 pm – 6:00 pm</ListGroupItem> */}
                    </ListGroup>
                  </Row>
                </CardBody>
                <CardFooter className="text-center">
                  <Link to="/signup">
                    <Button className="btn" outline color="success">
                      Enroll
                    </Button>
                  </Link>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" sm="6">
              <Card className="card-coin card-plain">
                {/* <CardHeader>
                <img
                  alt="..."
                  className="img-center img-fluid"
                  src={require("assets/img/gold.svg")}
                />
              </CardHeader> */}
                <CardBody>
                  <Row>
                    <Col className="text-center" md="12">
                      <h4 className="text-uppercase">Booster</h4>
                      <p>Here we make you absorb the maximum heat.</p>
                      <hr className="line-success" />
                    </Col>
                  </Row>
                  <Row>
                    <ListGroup>
                      <ListGroupItem>Class IXth</ListGroupItem>
                      {/* <ListGroupItem>MON- SAT</ListGroupItem>
                    <ListGroupItem>Class time - 4:00 pm – 5:00 pm</ListGroupItem> */}
                    </ListGroup>
                  </Row>
                </CardBody>
                <CardFooter className="text-center">
                  <Link to="/signup">
                    <Button className="btn" outline color="success">
                      Enroll
                    </Button>
                  </Link>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" sm="6">
              <Card className="card-coin card-plain">
                {/* <CardHeader>
                <img
                  alt="..."
                  className="img-center img-fluid"
                  src={require("assets/img/finisher.svg")}
                />
              </CardHeader> */}
                <CardBody>
                  <Row>
                    <Col className="text-center" md="12">
                      <h4 className="text-uppercase">Finisher</h4>
                      <p>You are the show now. Just Shine!</p>
                      <hr className="line-danger" />
                    </Col>
                  </Row>
                  <Row>
                    <ListGroup>
                      <ListGroupItem>Class Xth</ListGroupItem>
                      {/* <ListGroupItem>MON- SAT</ListGroupItem>
                    <ListGroupItem>Class time - 4:00 pm – 5:00 pm</ListGroupItem> */}
                    </ListGroup>
                  </Row>
                </CardBody>
                <CardFooter className="text-center">
                  <Link to="/signup">
                    <Button className="btn" outline color="success">
                      Enroll
                    </Button>
                  </Link>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default ChooseAPlan;
