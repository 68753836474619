import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

class About extends React.Component {
  render() {
    return (
      <div className="section section-javascript" id="javascriptComponents">
        <img
          alt="..."
          className="path path1"
          src={require("assets/img/path5.png")}
        />
        <div className="section">
          <Container>
            <Row className="py-50 justify-content-between align-items-center">
              <Col lg="12">
                <h1 className="text-center">
                  Welcome to NEEV - Laying Foundations
                  <hr className="line-success" />
                </h1>
                <p className="mt-4 text-justify">
                  We are an education - technology based start-up where we provide specially customised and designed syllabus for each and every individual student according to their needs with the help of data analytics to extract out the maximum potential of a child to help them grow every corner of their brain.
                </p>
                <p className="text-justify">
                  We don’t just focus only on the remembering quotient of the brain, rather we intend to equally develop the other untouched abilities of brain like comprehension, application, analysis etc. as well for a complete growth of the child.
                </p>
                <p className="text-justify">
                  We asses your weaknesses in different subjects and create personalised learned just for you which is mould in such a way that it helps to strengthen your studies as well as brain quotients simultaneously. 
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default About;
