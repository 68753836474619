import React from "react";
import classnames from "classnames";

import { Link } from "react-router-dom";

import Lottie from "react-lottie";
import animationData from "views/examples/IndexSections/18365-animaton-for-e-learning-web-site.json";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  // Label,
  // FormGroup,
  // InputGroupAddon,
  InputGroupText,
  InputGroup,
  UncontrolledAlert,
  Container,
  Row,
  Col,
  Modal,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

class LogInPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      squares1to6: "",
      squares7and8: "",
      email: localStorage.getItem("email"),
      token: localStorage.getItem("token"),
      userType: localStorage.getItem("userType"),
      password: "",
      showModal: false,
    };

    this.formErrors = {
      email: false,
      password: false,
      emailError: "",
      passwordError: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handelChange = this.handelChange.bind(this);
    this.showPopup = this.showPopup.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    document.body.classList.toggle("register-page");
    document.documentElement.addEventListener("mousemove", this.followCursor);
  }

  async showPopup() {
    this.setState({
      showModal: true,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (this.state.email.length > 0 && this.state.password.length > 0) {
      if (
        this.formErrors.email === false &&
        this.formErrors.password === false
      ) {
        const details = {
          email: this.state.email.toLowerCase().toString(),
          user_type: this.state.type,
          password: this.state.password.toString(),
        };
        await fetch(
          `/login/`,
          {
            method: "post",
            headers: { "Content-Type": "application/json; charset=UTF-8" },
            body: JSON.stringify(details),
          },
          { mode: "no-cors" }
        )
          .then((res) => res.json())
          .then(
            (result) => {
              console.log(result);
              this.setState({
                isLoaded: true,
                status: result.status,
                info: result.info,
                userType: result.user_type,
                token: result.token,
              });
              this.showPopup();
              if (this.state.status === "success") {
                localStorage.setItem("email", details.email);
                localStorage.setItem("userType", this.state.userType);
                localStorage.setItem("token", this.state.token);
                if (
                  this.state.userType.toString() === "1" ||
                  this.state.userType.toString() === "3"
                )
                  return window.location.replace(`/student/dashboard`);
                if (this.state.userType.toString() === "2")
                  return window.location.replace("/teacher/dashboard");
              } else {
                return;
              }
            },
            (error) => {
              this.setState({
                isLoaded: false,
                info: "Some error happened...try again later",
                error,
              });
              console.log(error);
            }
          );
      }
    } else {
      console.log("Invalid Form");
    }
  }

  handelChange(event) {
    event.preventDefault();
    const { name, value } = event.target;
    switch (name) {
      case "email":
        this.formErrors.email =
          emailRegex.test(value) && value.length > 6 ? false : true;
        this.formErrors.emailError =
          emailRegex.test(value) && value.length > 6
            ? ""
            : "invalid email address";
        this.setState({
          email: value,
        });
        break;
      case "Radios":
        this.setState({
          type: value,
        });
        break;
      case "pass":
        this.formErrors.password =
          value.length < 6 && value.length > 0 ? true : false;
        this.formErrors.passwordError =
          value.length < 6 && value.length > 0
            ? "password must be more than 6 characters"
            : "";
        this.setState({
          password: value,
        });
        break;
      default:
        break;
    }
  }

  componentWillUnmount() {
    document.body.classList.toggle("register-page");
    document.documentElement.removeEventListener(
      "mousemove",
      this.followCursor
    );
  }
  followCursor = (event) => {
    let posX = event.clientX - window.innerWidth / 2;
    let posY = event.clientY - window.innerWidth / 6;
    this.setState({
      squares1to6:
        "perspective(500px) rotateY(" +
        posX * 0.05 +
        "deg) rotateX(" +
        posY * -0.05 +
        "deg)",
      squares7and8:
        "perspective(500px) rotateY(" +
        posX * 0.02 +
        "deg) rotateX(" +
        posY * -0.02 +
        "deg)",
    });
  };
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <>
        {this.state.info && (
          <Modal
            modalClassName={
              this.state.status === "success"
                ? "modal-mini modal-success"
                : this.state.status === "failure"
                ? "modal-mini modal-danger"
                : "modal-mini modal-danger"
            }
            isOpen={this.state.showModal}
          >
            <div className="modal-body">
              {typeof this.state.info === "object"
                ? Object.keys(this.state.info).map((item, i) => (
                    <div key={i}>
                      {item}: {this.state.info[item]}
                    </div>
                  ))
                : this.state.info}
            </div>
            {this.state.status !== "success" && (
              <div className="modal-footer">
                <Button
                  className="btn-neutral"
                  color="link"
                  onClick={() =>
                    this.setState({
                      showModal: false,
                    })
                  }
                  type="button"
                >
                  Close
                </Button>
                <Button
                  className="btn-neutral"
                  color="link"
                  tag={Link}
                  to="/signup"
                  onClick={() =>
                    this.setState({
                      showModal: false,
                    })
                  }
                  type="button"
                >
                  Register
                </Button>
              </div>
            )}
          </Modal>
        )}

        <IndexNavbar />
        <div className="wrapper">
          <div className="page-header">
            <div className="page-header-image" />
            <div className="content">
              <Container>
                <Row>
                  <Col className="offset-lg-0 offset-md-3" lg="6" md="7">
                    <Card className="card-register">
                      <CardHeader>
                        <CardTitle tag="h4">Log In</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <Form onSubmit={this.handleSubmit} className="form">
                          <InputGroup
                            className={classnames({
                              "input-group-focus": this.state.emailFocus,
                              "has-danger": this.formErrors.email,
                            })}
                          >
                            {/* <Button addonType="prepend"> */}
                            <InputGroupText>
                              <i className="tim-icons icon-email-85" />
                            </InputGroupText>
                            {/* </Button> */}
                            <Input
                              placeholder="Email"
                              name="email"
                              type="text"
                              onFocus={(e) =>
                                this.setState({ emailFocus: true })
                              }
                              onBlur={(e) =>
                                this.setState({ emailFocus: false })
                              }
                              onChange={this.handelChange}
                            />
                          </InputGroup>
                          <UncontrolledAlert
                            className={classnames({
                              "d-none alert-with-icon": !this.formErrors.email,
                              "alert-with-icon": this.formErrors.email,
                            })}
                            color="danger"
                          >
                            <span>{this.formErrors.emailError}</span>
                          </UncontrolledAlert>
                          <InputGroup
                            className={classnames({
                              "input-group-focus": this.state.passwordFocus,
                              "has-danger": this.formErrors.password,
                            })}
                          >
                            {/* <Button addonType="prepend"> */}
                            <InputGroupText>
                              <i className="tim-icons icon-lock-circle" />
                            </InputGroupText>
                            {/* </Button> */}
                            <Input
                              placeholder="Password"
                              name="pass"
                              type="password"
                              onFocus={(e) =>
                                this.setState({ passwordFocus: true })
                              }
                              onBlur={(e) =>
                                this.setState({ passwordFocus: false })
                              }
                              onChange={this.handelChange}
                            />
                          </InputGroup>
                          <UncontrolledAlert
                            className={classnames({
                              "d-none alert-with-icon":
                                !this.formErrors.password,
                              "alert-with-icon": this.formErrors.password,
                            })}
                            color="danger"
                          >
                            <span>{this.formErrors.passwordError}</span>
                          </UncontrolledAlert>
                          <CardFooter>
                            <Button
                              type="submit"
                              value="submit"
                              color="success"
                              size="lg"
                            >
                              Log In
                            </Button>
                          </CardFooter>
                          <Row className="p-2">
                            <Col md="6">
                              <span className="text-dark">
                                Don't have account?
                              </span>
                              <Button
                                className="btn-neutral"
                                color="link"
                                tag={Link}
                                to="/signup"
                                type="button"
                              >
                                Register
                              </Button>
                            </Col>
                            <Col md="6">
                              <span className="text-dark">
                                Forgot Password?
                              </span>
                              <Button
                                className="btn-neutral"
                                color="link"
                                tag={Link}
                                to="/forgot-pass"
                                type="button"
                              >
                                Click Here
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col
                    lg="6"
                    md="5"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Lottie
                      options={defaultOptions}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

export default LogInPage;
