import React from "react";
// Images
import path4 from "../../../assets/img/path4.png";
import path5 from "../../../assets/img/path5.png";
import path2 from "../../../assets/img/path2.png";
import assessment from "../../../assets/img/assessment.svg";
import teacher_blue from "../../../assets/img/teacher-blue.svg";
import homework_green from "../../../assets/img/homework-green.svg";
import self_study from "../../../assets/img/self-study.svg";
import routine from "../../../assets/img/routine.svg";
import parent_teacher from "../../../assets/img/parent-teacher.svg";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

class Benefits extends React.Component {
  render() {
    return (
      <section className="section section-lg benefits">
        <img alt="..." className="path d-none d-xl-block" src={path4} />
        <img alt="..." className="path2 d-none d-sm-block" src={path5} />
        <img alt="..." className="path3" src={path2} />
        <Container>
          <Row className="justify-content-center d-block d-sm-none row">
            <Col lg="12">
              <h1 className="text-center">
                Your best benefit
                <hr className="line-success" />
              </h1>
              <Row className="row-grid justify-content-center">
                <Col coffset="6"></Col>
                <Col lg="6" sm="6">
                  <div className="info">
                    <img src={assessment} alt="..." />
                    <h3 className="info-title">
                      Assessments of mental Parameters
                    </h3>
                    <p>
                      We at NEEV asses child’s brain to identify his strong and
                      weak regions so that we could understand the kind of needs
                      each and every individual child wants and streamline their
                      efforts towards correct direction.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="row-grid justify-content-center">
                <Col lg="6" sm="6">
                  <div className="info">
                    <img src={teacher_blue} alt="..." />
                    <h3 className="info-title">
                      Personalised Offline Teaching and Learned
                    </h3>
                    <p>
                      We provide specially customised, designed and completely
                      personalised Teaching along with Home-work and self-study
                      routine and material to each and every student according
                      to their strengths and weaknesses which help them to
                      fasten their strengths and overcome their weaknesses with
                      directional efforts.
                    </p>
                  </div>
                </Col>
                <Col coffset="6"></Col>
              </Row>
              <Row className="row-grid justify-content-center">
                <Col coffset="6"></Col>
                <Col lg="6" sm="6">
                  <div className="info">
                    <img src={homework_green} alt="..." />
                    <h3 className="info-title">Personalised Homework</h3>
                    <p>
                      Not just at our place, But also at their place. We provide
                      personalised home-work along with Self study material to
                      every child.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="row-grid justify-content-center">
                <Col lg="6" sm="6">
                  <div className="info">
                    <img src={self_study} alt="..." />
                    <h3 className="info-title">
                      Personalised self study Notes
                    </h3>
                    <p>
                      We all know how huge the heap of study can be. But DO NOT
                      WORRY! With us, you need not dig up everything. We provide
                      special (or especially) customised self-study notes based
                      on your capabilities so that you can gain more with lesser
                      efforts.
                    </p>
                  </div>
                </Col>
                <Col coffset="6"></Col>
              </Row>
              <Row className="row-grid justify-content-center">
                <Col coffset="6"></Col>
                <Col lg="6" sm="6">
                  <div className="info">
                    <img src={routine} alt="..." />
                    <h3 className="info-title">
                      Personalised self study routine
                    </h3>
                    <p>
                      Time is precious! And that's why we have your study
                      routine personalised so that you can divide your valuable
                      time accordingly and focus more on the much needed areas.
                      Also, you get more time for playing and pursuing your
                      hobbies.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="row-grid justify-content-center">
                <Col lg="6" sm="6">
                  <div className="info">
                    <img src={parent_teacher} alt="..." />
                    <h3 className="info-title"> Parent-Teacher connectivity</h3>
                    <p>
                      Parents are not just the first teacher of a child, in fact
                      they are the most influencing teacher for the child
                      throughout his life. We have designed different
                      engagements where we want parents participations to have a
                      track of their child’s progress.
                    </p>
                  </div>
                </Col>
                <Col coffset="6"></Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center d-none d-sm-block">
            <Col lg="12">
              <h1 className="text-center">
                Your best benefit
                <hr className="line-success" />
              </h1>
              <Row className="row-grid justify-content-center py-5">
                <Col coffset="6">
                  <div className="info text-right pr-5">
                    <h3 className="info-title">
                      Assessments of mental Parameters
                    </h3>
                    <p>
                      We at NEEV asses child’s brain to identify his strong and
                      weak regions so that we could understand the kind of needs
                      each and every individual child wants and streamline their
                      efforts towards correct direction.
                    </p>
                  </div>
                </Col>
                <Col lg="6" sm="6">
                  <div className="info text-left pl-5">
                    <img src={assessment} alt="..." />
                    {/* <h3 className="info-title">Assessments of mental Parameters</h3>
                    <p>
                    We at NEEV asses child’s brain to identify his strong and weak regions so that we could  understand the kind of needs each and every individual child wants and streamline their efforts towards correct direction.
                    </p> */}
                  </div>
                </Col>
              </Row>
              <Row className="row-grid justify-content-center py-5">
                <Col lg="6" sm="6">
                  <div className="info text-right pr-5">
                    <img src={teacher_blue} alt="..." />
                    {/* <h3 className="info-title">Personalised Offline Teaching and Learned</h3>
                    <p>
                    We provide specially customised, designed and completely personalised Teaching along with Home-work and self-study routine and material to each and every student according to their strengths and weaknesses which help them to fasten their strengths and overcome their weaknesses with directional efforts.
                    </p> */}
                  </div>
                </Col>
                <Col coffset="6">
                  <div className="info text-left pl-5">
                    <h3 className="info-title">
                      Personalised Offline Teaching and Learned
                    </h3>
                    <p>
                      We provide specially customised, designed and completely
                      personalised Teaching along with Home-work and self-study
                      routine and material to each and every student according
                      to their strengths and weaknesses which help them to
                      fasten their strengths and overcome their weaknesses with
                      directional efforts.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="row-grid justify-content-center py-5">
                <Col coffset="6">
                  <div className="info text-right pr-5">
                    <h3 className="info-title">Personalised Homework</h3>
                    <p>
                      Not just at our place, But also at their place. We provide
                      personalised home-work along with Self study material to
                      every child.
                    </p>
                  </div>
                </Col>
                <Col lg="6" sm="6">
                  <div className="info text-left pl-5">
                    <img src={homework_green} alt="..." />
                    {/* <h3 className="info-title">Personalised Homework</h3>
                    <p>
                    Not just at our place, But also at their place. We provide personalised home-work along with Self study material to every child.
                    </p> */}
                  </div>
                </Col>
              </Row>
              <Row className="row-grid justify-content-center py-5">
                <Col lg="6" sm="6">
                  <div className="info text-right pr-5">
                    <img src={self_study} alt="..." />
                    {/* <h3 className="info-title">Personalised self study Notes</h3> */}
                  </div>
                </Col>
                <Col coffset="6">
                  <div className="info text-left pl-5">
                    <h3 className="info-title">
                      Personalised self study Notes
                    </h3>
                    <p>
                      We all know how huge the heap of study can be. But DO NOT
                      WORRY! With us, you need not dig up everything. We provide
                      special (or especially) customised self-study notes based
                      on your capabilities so that you can gain more with lesser
                      efforts.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="row-grid justify-content-center py-5">
                <Col coffset="6">
                  <div className="info text-right pr-5">
                    <h3 className="info-title">
                      Personalised self study routine
                    </h3>
                    <p>
                      Time is precious! And that's why we have your study
                      routine personalised so that you can divide your valuable
                      time accordingly and focus more on the much needed areas.
                      Also, you get more time for playing and pursuing your
                      hobbies.
                    </p>
                  </div>
                </Col>
                <Col lg="6" sm="6">
                  <div className="info text-left pl-5">
                    <img src={routine} alt="..." />
                    {/* <h3 className="info-title">Personalised self study routine</h3> */}
                  </div>
                </Col>
              </Row>
              <Row className="row-grid justify-content-center py-5">
                <Col lg="6" sm="6">
                  <div className="info text-right pr-5">
                    <img src={parent_teacher} alt="..." />
                    {/* <h3 className="info-title"> Parent-Teacher connectivity</h3>
                    <p>
                    Parents are not just the first teacher of a child, in fact they are the most influencing teacher for the child throughout his life. We have designed different  engagements where we want parents participations to have a track of their child’s progress.
                    </p> */}
                  </div>
                </Col>
                <Col coffset="6">
                  <div className="info text-left pl-5">
                    <h3 className="info-title"> Parent-Teacher connectivity</h3>
                    <p>
                      Parents are not just the first teacher of a child, in fact
                      they are the most influencing teacher for the child
                      throughout his life. We have designed different
                      engagements where we want parents participations to have a
                      track of their child’s progress.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default Benefits;
