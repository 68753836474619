import React from "react";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import About from "views/examples/IndexSections/About.jsx";
import ScrollToTop from "views/examples/IndexSections/ScrollToTop.jsx";
import Footer from "components/Footer/Footer.jsx";

class AboutPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.classList.toggle("landing-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("landing-page");
  }
  render() {
    return (
      <>
        <IndexNavbar />
        <div className="wrapper">
          <About />
        </div>
        <Footer />
        <ScrollToTop />
      </>
    );
  }
}

export default AboutPage;
