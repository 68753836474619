import React from "react";
import classnames from "classnames";

import { Link } from "react-router-dom";

import Lottie from "react-lottie";
import animationData from "views/examples/IndexSections/23565-ready-to-learn.json";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  // InputGroupAddon,Buttonxt,
  InputGroup,
  InputGroupText,
  UncontrolledAlert,
  Container,
  Row,
  Col,
  Modal,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      squares1to6: "",
      squares7and8: "",
      name: "",
      email: "",
      type: 1,
      phone: "",
      password: "",
      confirmPassword: "",
      tncagree: true,
      showModal: false,
      status: "failure",
      info: null,
    };

    this.formErrors = {
      name: false,
      email: false,
      phone: false,
      password: false,
      confirmPassword: false,
      nameError: "",
      emailError: "",
      phoneError: "",
      passwordError: "",
      confirmPasswordError: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handelChange = this.handelChange.bind(this);
    this.showPopup = this.showPopup.bind(this);
    this.toTitleCase = this.toTitleCase.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    document.body.classList.toggle("register-page");
    document.documentElement.addEventListener("mousemove", this.followCursor);
  }

  async showPopup() {
    this.setState({
      showModal: true,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (this.state.tncagree) {
      if (
        this.state.name.length > 0 &&
        this.state.email.length > 0 &&
        this.state.phone.length > 0 &&
        this.state.password.length > 0 &&
        this.state.confirmPassword.length > 0
      ) {
        if (
          this.formErrors.name === false &&
          this.formErrors.email === false &&
          this.formErrors.phone === false &&
          this.formErrors.password === false &&
          this.formErrors.confirmPassword === false
        ) {
          const details = {
            full_name: this.toTitleCase(this.state.name.toString()),
            email: this.state.email.toLowerCase().toString(),
            phone: this.state.phone,
            user_type:
              this.state.type && this.state.type === undefined
                ? 1
                : this.state.type,
            password: this.state.password.toString(),
            password2: this.state.confirmPassword.toString(),
          };
          console.log(this.state.type);
          await fetch(
            `/register/`,
            {
              method: "post",
              headers: { "Content-Type": "application/json; charset=UTF-8" },
              body: JSON.stringify(details),
            },
            { mode: "no-cors" }
          )
            .then((res) => res.json())
            .then(
              (result) => {
                this.setState({
                  isLoaded: true,
                  status: result.status,
                  info: result.info,
                  token: result.token,
                });
                this.showPopup();
                if (this.state.status === "success") {
                  localStorage.setItem("email", details.email);
                  localStorage.setItem("userType", details.type);
                  localStorage.setItem("token", this.state.token);
                  return window.location.replace("/login");
                } else {
                  return;
                }
              },
              // Note: it's important to handle errors here
              // instead of a catch() block so that we don't swallow
              // exceptions from actual bugs in components.
              (error) => {
                this.setState({
                  isLoaded: false,
                  token: null,
                  error,
                });
                this.showPopup();
              }
            );
          return;
        }
      } else {
        return console.log("Invalid Form");
      }
    }
  }

  handelChange(event) {
    event.preventDefault();
    const { name, value } = event.target;
    switch (name) {
      case "name":
        this.formErrors.name =
          value.length < 3 && value.length > 0 ? true : false;
        this.formErrors.nameError =
          value.length < 3 ? "At least 3 letters required for Name" : "";
        this.setState({
          name: value,
        });
        break;
      case "email":
        this.formErrors.email =
          emailRegex.test(value) && value.length > 6 ? false : true;
        this.formErrors.emailError =
          emailRegex.test(value) && value.length > 6
            ? ""
            : "invalid email address";
        this.setState({
          email: value,
        });
        break;
      case "phone":
        this.formErrors.phone = value.length !== 10 ? true : false;
        this.formErrors.phoneError =
          value.length !== 10 ? "Phone number must be of 10 digits" : false;
        this.setState({
          phone: value,
        });
        break;
      case "Radios":
        this.setState({
          type: value,
        });
        break;
      case "pass":
        this.formErrors.password =
          value.length < 6 && value.length > 0 ? true : false;
        this.formErrors.passwordError =
          value.length < 6 && value.length > 0
            ? "password must be more than 6 characters"
            : "";
        this.setState({
          password: value,
        });
        break;
      case "confpass":
        this.formErrors.confirmPassword =
          value === this.state.password ? false : true;
        this.formErrors.confirmPasswordError =
          value !== this.state.password ? "both passwords must be equal" : "";
        this.setState({
          confirmPassword: value,
        });
        break;
      case "tncagree":
        this.setState({
          tncagree: !this.state.tncagree,
        });
        break;
      default:
        break;
    }
  }

  componentWillUnmount() {
    document.body.classList.toggle("register-page");
    document.documentElement.removeEventListener(
      "mousemove",
      this.followCursor
    );
  }

  toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  };

  followCursor = (event) => {
    let posX = event.clientX - window.innerWidth / 2;
    let posY = event.clientY - window.innerWidth / 6;
    this.setState({
      squares1to6:
        "perspective(500px) rotateY(" +
        posX * 0.05 +
        "deg) rotateX(" +
        posY * -0.05 +
        "deg)",
      squares7and8:
        "perspective(500px) rotateY(" +
        posX * 0.02 +
        "deg) rotateX(" +
        posY * -0.02 +
        "deg)",
    });
  };
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <>
        {this.state.info && (
          <Modal
            modalClassName={
              this.state.status === "success"
                ? "modal-mini modal-success"
                : this.state.status === "failure"
                ? "modal-mini modal-danger"
                : "modal-mini modal-danger"
            }
            isOpen={this.state.showModal}
          >
            <div className="modal-body">
              {typeof this.state.info === "object"
                ? Object.keys(this.state.info).map((item, i) => (
                    <div key={i}>
                      {item}: {this.state.info[item]}
                    </div>
                  ))
                : this.state.info}
            </div>
            {this.state.status !== "success" && (
              <div className="modal-footer">
                <Button
                  className="btn-neutral"
                  color="link"
                  onClick={() =>
                    this.setState({
                      showModal: false,
                    })
                  }
                  type="button"
                >
                  Close
                </Button>
                <Button
                  className="btn-neutral"
                  color="link"
                  tag={Link}
                  to="/login"
                  onClick={() =>
                    this.setState({
                      showModal: false,
                    })
                  }
                  type="button"
                >
                  Log In
                </Button>
              </div>
            )}
          </Modal>
        )}

        <IndexNavbar />
        <div className="wrapper register-page">
          <div className="page-header">
            <div className="page-header-image" />
            <div className="content">
              <Container>
                <Row>
                  <Col className="offset-lg-0 offset-md-3" lg="6" md="7">
                    <Card className="card-register">
                      <CardHeader>
                        <CardTitle tag="h4">Register</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <Form onSubmit={this.handleSubmit} className="form">
                          <InputGroup
                            className={classnames({
                              "input-group-focus": this.state.fullNameFocus,
                              "has-danger": this.formErrors.name,
                            })}
                          >
                            {/* <Button addonType="prepend"> */}
                            <InputGroupText>
                              <i className="tim-icons icon-single-02" />
                            </InputGroupText>
                            {/* </Button> */}
                            <Input
                              placeholder="Full Name"
                              name="name"
                              onFocus={(e) =>
                                this.setState({ fullNameFocus: true })
                              }
                              onBlur={(e) =>
                                this.setState({ fullNameFocus: false })
                              }
                              onChange={this.handelChange}
                            />
                          </InputGroup>
                          <UncontrolledAlert
                            className={classnames({
                              "d-none alert-with-icon": !this.formErrors.name,
                              "alert-with-icon": this.formErrors.name,
                            })}
                            color="danger"
                          >
                            <span>{this.formErrors.nameError}</span>
                          </UncontrolledAlert>
                          <InputGroup
                            className={classnames({
                              "input-group-focus": this.state.emailFocus,
                              "has-danger": this.formErrors.email,
                            })}
                          >
                            {/* <Button addonType="prepend"> */}
                            <InputGroupText>
                              <i className="tim-icons icon-email-85" />
                            </InputGroupText>
                            {/* </Button> */}
                            <Input
                              placeholder="Email"
                              name="email"
                              onFocus={(e) =>
                                this.setState({ emailFocus: true })
                              }
                              onBlur={(e) =>
                                this.setState({ emailFocus: false })
                              }
                              onChange={this.handelChange}
                            />
                          </InputGroup>
                          <UncontrolledAlert
                            className={classnames({
                              "d-none alert-with-icon": !this.formErrors.email,
                              "alert-with-icon": this.formErrors.email,
                            })}
                            color="danger"
                          >
                            <span>{this.formErrors.emailError}</span>
                          </UncontrolledAlert>
                          <InputGroup
                            className={classnames({
                              "input-group-focus": this.state.phoneFocus,
                              "has-danger": this.formErrors.phone,
                            })}
                          >
                            {/* <Button addonType="prepend"> */}
                            <InputGroupText>
                              <i className="tim-icons icon-badge" />
                            </InputGroupText>
                            {/* </Button> */}
                            <Input
                              placeholder="Contact Number"
                              name="phone"
                              onFocus={(e) =>
                                this.setState({ phoneFocus: true })
                              }
                              onBlur={(e) =>
                                this.setState({ phoneFocus: false })
                              }
                              onChange={this.handelChange}
                            />
                          </InputGroup>
                          <UncontrolledAlert
                            className={classnames({
                              "d-none alert-with-icon": !this.formErrors.phone,
                              "alert-with-icon": this.formErrors.phone,
                            })}
                            color="danger"
                          >
                            <span>{this.formErrors.phoneError}</span>
                          </UncontrolledAlert>
                          <FormGroup check className="form-check-radio">
                            Register as:&nbsp;&nbsp;&nbsp;
                            <Label check>
                              <Input
                                defaultChecked
                                value="1"
                                id="Radios1"
                                name="Radios"
                                type="radio"
                                onChange={this.handelChange}
                              />
                              <span className="form-check-sign" />
                              Student &nbsp;
                            </Label>
                            <Label check>
                              <Input
                                value="2"
                                id="Radios2"
                                name="Radios"
                                type="radio"
                                onChange={this.handelChange}
                              />
                              <span className="form-check-sign" />
                              Teacher &nbsp;
                            </Label>
                            <Label check>
                              <Input
                                value="3"
                                id="Radios3"
                                name="Radios"
                                type="radio"
                                onChange={this.handelChange}
                              />
                              <span className="form-check-sign" />
                              Parent &nbsp;
                            </Label>
                          </FormGroup>
                          <InputGroup
                            className={classnames({
                              "input-group-focus": this.state.passwordFocus,
                              "has-danger": this.formErrors.password,
                            })}
                          >
                            {/* <Button addonType="prepend"> */}
                            <InputGroupText>
                              <i className="tim-icons icon-key-25" />
                            </InputGroupText>
                            {/* </Button> */}
                            <Input
                              placeholder="Password"
                              name="pass"
                              type="password"
                              onFocus={(e) =>
                                this.setState({ passwordFocus: true })
                              }
                              onBlur={(e) =>
                                this.setState({ passwordFocus: false })
                              }
                              onChange={this.handelChange}
                            />
                          </InputGroup>
                          <UncontrolledAlert
                            className={classnames({
                              "d-none alert-with-icon":
                                !this.formErrors.password,
                              "alert-with-icon": this.formErrors.password,
                            })}
                            color="danger"
                            closeAriaLabel="Close"
                          >
                            <span>{this.formErrors.passwordError}</span>
                          </UncontrolledAlert>
                          <InputGroup
                            className={classnames({
                              "input-group-focus":
                                this.state.ConfirmPasswordFocus,
                              "has-danger": this.formErrors.confirmPassword,
                            })}
                          >
                            {/* <Button addonType="prepend"> */}
                            <InputGroupText>
                              <i className="tim-icons icon-key-25" />
                            </InputGroupText>
                            {/* </Button> */}
                            <Input
                              placeholder="Confirm Password"
                              name="confpass"
                              type="confirmPassword"
                              onFocus={(e) =>
                                this.setState({ ConfirmPasswordFocus: true })
                              }
                              onBlur={(e) =>
                                this.setState({ ConfirmPasswordFocus: false })
                              }
                              onChange={this.handelChange}
                            />
                          </InputGroup>
                          <UncontrolledAlert
                            className={classnames({
                              "d-none alert-with-icon":
                                !this.formErrors.confirmPassword,
                              "alert-with-icon":
                                this.formErrors.confirmPassword,
                            })}
                            color="danger"
                          >
                            <span>{this.formErrors.confirmPasswordError}</span>
                          </UncontrolledAlert>
                          <FormGroup check className="text-left">
                            <Label check>
                              <Input
                                type="checkbox"
                                name="tncagree"
                                onChange={this.handelChange}
                                defaultChecked={this.state.tncagree}
                              />
                              <span className="form-check-sign" />I agree to the{" "}
                              <a
                                href="https://tnc.com"
                                onClick={(e) => e.preventDefault()}
                              >
                                terms and conditions
                              </a>
                              .
                            </Label>
                          </FormGroup>
                          <CardFooter>
                            <Row>
                              <Col>
                                <Button
                                  type="submit"
                                  value="submit"
                                  color="success"
                                  size="lg"
                                >
                                  Get Started
                                </Button>
                              </Col>
                              <Col>
                                <span className="text-dark">
                                  Have an account?
                                </span>
                                <Button
                                  className="btn-neutral"
                                  color="link"
                                  tag={Link}
                                  to="/login"
                                  type="button"
                                >
                                  Log In
                                </Button>
                              </Col>
                            </Row>
                          </CardFooter>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col
                    lg="6"
                    md="5"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Lottie
                      options={defaultOptions}
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

export default RegisterPage;
